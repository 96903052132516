import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'dpqa-icon-alert',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './icon-alert.component.html',
  styleUrl: './icon-alert.component.less',
})
export class IconAlertComponent {
  public iconName = input.required<string>();
  public iconSize = input.required<number>();
  public alertMessage = input.required<string>();
  public alertDescription = input<string>();
  public type = input<'warning' | 'error'>('warning');
}
