<div class="icon-alert">
  <div class="icon-alert__icon" [class]="type() ? 'icon-alert__icon--' + type() : ''">
    <dpqa-icon [iconName]="iconName()" [iconSize]="iconSize()"></dpqa-icon>
  </div>
  <div>
    <h1>{{ alertMessage() }}</h1>
    @if (alertDescription()) {
      <h2>{{ alertDescription() }}</h2>
    }
  </div>
</div>
